import comBaCal from "../assets/rest/ComBaCaL_LOGO.png"
import provotum from "../assets/rest/provotum_LOGO.svg"
import { useTranslation } from "react-i18next"

function Projects() {

  const {t, i18n} = useTranslation()


    return (
      <div className='bg-redOfBackgroundPic w-full py-10 md:py-20' id="projects">
        <div className="flex justify-center">
          <h2 className="font-arimo font-semibold text-3xl">{t("titleProjects")}</h2>
        </div>
        <div className="mx-auto max-w-screen-lg px-3 py-6">
          <div className="flex flex-col gap-6">
          
            <div className="flex flex-col items-center gap-x-8 rounded-md bg-beigeOfMePic p-3 md:flex-row">
              <div className="flex md:w-full justify-center">
                <a href="https://www.combacal.org/">
                  <img src={comBaCal} className="object-cover h-40"/>
                </a>
              </div>
              <div>
                <div className="flex flex-col items-center gap-y-2 md:flex-row">
                  <div className="flex flex-col items-center gap-y-2 md:flex-row">
                    <a className="hover:text-cyan-400" href="https://www.combacal.org/">
                      <h3 className="text-xl font-semibold">ComBaCal</h3>
                    </a>
                  </div>
                  <div className="ml-3 flex gap-2">
                    <div className="rounded-md px-2 py-1 text-xs font-semibold bg-neutral-700 text-white">Symfony</div>
                    <div className="rounded-md px-2 py-1 text-xs font-semibold bg-neutral-700 text-white">React</div>
                  </div>
                </div>
                <p className="mt-3 text-justify md:text-left">{t("2Projects")}</p>
              </div>
            </div>

            <div className="flex flex-col items-center gap-x-8 rounded-md bg-beigeOfMePic p-3 md:flex-row">
              <div className="flex md:w-full justify-center">
                <a href="http://provotum.ch/">
                  <img src={provotum} className="object-cover h-40"/>
                </a>
              </div>
              <div>
                <div className="flex flex-col items-center gap-y-2 md:flex-row">
                  <div className="flex flex-col items-center gap-y-2 md:flex-row">
                    <a className="hover:text-cyan-400" href="http://provotum.ch/">
                      <h3 className="text-xl font-semibold">Provotum </h3>
                    </a>
                  </div>
                  <div className="ml-3 flex gap-2">
                    <div className="rounded-md px-2 py-1 text-xs font-semibold bg-neutral-700 text-white">React</div>
                    <div className="rounded-md px-2 py-1 text-xs font-semibold bg-neutral-700 text-white">Typescript</div>
                    <div className="rounded-md px-2 py-1 text-xs font-semibold bg-neutral-700 text-white">Rust</div>
                  </div>
                </div>
                <p className="mt-3 text-justify md:text-left">{t("1Projects")}</p>
              </div>
            </div>

          </div> 
        </div>
      </div>
      
    );
  }

  
  export default Projects;
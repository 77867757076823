import Title from "./sections/Title";
import AboutMe from "./sections/AboutMe";
import Projects from "./sections/Projects";
import Cybersec from "./sections/Cybersec";
import Navbar from "./sections/Navbar";
import Footer from "./sections/Footer";

function App() {
  return (
    <div>
      <Navbar />
      <Title />
      <AboutMe />
      <Projects />
      <Cybersec />
      <Footer />
    </div>
  );
}

export default App;

import { useState } from "react";
import me from "../assets/rest/me.png"
import thesis from "../assets/rest/thesis.pdf"
import {FaJava, FaPython, FaReact, FaGitAlt, FaDocker, FaPhp} from "react-icons/fa"
import { useTranslation } from "react-i18next"

function AboutMe() {

  const [buttonState, setButtonState] = useState(0)
  const {t, i18n} = useTranslation()

    return (
      <div className='flex w-full justify-center items-center py-5 md:py-20' id="about">
        <div className="block mx-auto px-15">
          <div className="md:flex max-w-6xl">
            <div className="flex justify-center w-full md:relative md:w-4/12">
              <div className="md:absolute right-0 md:mr-7 lg:before:absolute lg:before:h-full lg:before:w-full lg:before:bg-redOfBackgroundPic lg:before:-left-10 lg:before:top-10 lg:before:-z-10">
                <img src={me} className="object-cover h-96 md:h-64 lg:h-80 xl:h-96"/>
              </div>
            </div>
            <div className="w-full md:w-8/12">
              <h2 className="flex justify-center md:block pt-6 md:pt-0 font-arimo font-semibold text-3xl">{t("titleAbout")}</h2>
              <p className="px-8 md:px-0 text-justify md:text-left">{t("textAbout")}</p>
                <br />
                  <ul class="flex justify-center md:justify-start items-center space-x-8 pt-2 md:pt-0">
                    <li>
                      {buttonState===0 ? 
                        (<div className="border-b-2 border-redOfBackgroundPic "><button onClick={() => setButtonState(0)} class="font-medium tracking-wide text-redOfBackgroundPic transition-colors duration-200 hover:text-deep-purple-accent-400">
                          {t("skillAbout")}
                        </button></div>) 
                        :
                        (<div className="border-b-2 w-1/2 hover:w-full hover:border-redOfBackgroundPic transition-all duration-300"><button onClick={() => setButtonState(0)} class="font-medium tracking-wide text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-redOfBackgroundPic">
                          {t("skillAbout")}
                        </button></div>)
                      }
                    </li>
                    <li>
                      {buttonState===1 ?
                        <div className="border-b-2 border-redOfBackgroundPic"><button onClick={() => setButtonState(1)} class="font-medium tracking-wide text-redOfBackgroundPic transition-colors duration-200 hover:text-deep-purple-accent-400">
                          {t("educationAbout")}
                        </button></div>
                        :
                        <div className="border-b-2 w-1/2 hover:w-full hover:border-redOfBackgroundPic transition-all duration-300"><button onClick={() => setButtonState(1)} class="font-medium tracking-wide text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-redOfBackgroundPic">
                          {t("educationAbout")}
                        </button></div>
                      }
                    </li>
                    <li>
                      {buttonState===2 ? 
                        <div className="border-b-2 border-redOfBackgroundPic"><button onClick={() => setButtonState(2)} class="font-medium tracking-wide text-redOfBackgroundPic transition-colors duration-200 hover:text-deep-purple-accent-400">
                          {t("expAbout")}
                        </button></div>
                        :
                        <div className="border-b-2 w-1/2 hover:w-full hover:border-redOfBackgroundPic transition-all duration-300"><button onClick={() => setButtonState(2)} class="font-medium tracking-wide text-gray-700 transition-colors duration-300 hover:text-deep-purple-accent-400 hover:text-redOfBackgroundPic">
                          {t("expAbout")}
                        </button></div>
                      }
                    </li>
                  </ul>
                  {buttonState===0 ? ( // Skills
                    <div className="relative mx-auto flex w-full flex-col">
                          <div className="pt-4">
                            <div className="grid gap-10 grid-cols-2 lg:grid-cols-3 pl-9 sm:pl-36 md:px-0">
                              <div className="flex items-center">
                                <a href="https://www.java.com/de/">
                                  <button className="flex justify-center items-center bg-redOfBackgroundPic w-14 h-14 rounded-full font-bold">
                                    <FaJava size={30} />
                                  </button>
                                </a>
                                <div className="flex flex-col justify-center ml-3">
                                  <p className="text-lg font-bold">Java</p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <a href="https://react.dev/">
                                <button className="flex justify-center items-center bg-redOfBackgroundPic w-14 h-14 rounded-full">
                                  <FaReact size={30} className="" />
                                </button>
                                </a>
                                <div className="flex flex-col justify-center ml-3">
                                  <p className="text-lg font-bold">React</p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <a href="https://www.php.net/">
                                <button className="flex justify-center items-center bg-redOfBackgroundPic w-14 h-14 rounded-full">
                                  <FaPhp size={30} className="" />
                                </button>
                                </a>
                                <div className="flex flex-col justify-center ml-3">
                                  <p className="text-lg font-bold">PHP</p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <a href="https://www.python.org/">
                                <button className="flex justify-center items-center bg-redOfBackgroundPic w-14 h-14 rounded-full">
                                  <FaPython size={27} className="" />
                                </button>
                                </a>
                                <div className="flex flex-col justify-center ml-3">
                                  <p className="text-lg font-bold">Python</p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <a href="https://www.docker.com/">
                                <button className="flex justify-center items-center bg-redOfBackgroundPic w-14 h-14 rounded-full">
                                  <FaDocker size={27} className="" />
                                </button>
                                </a>
                                <div className="flex flex-col justify-center ml-3">
                                  <p className="text-lg font-bold">Docker</p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                <a href="https://git-scm.com/">
                                <button className="flex justify-center items-center bg-redOfBackgroundPic w-14 h-14 rounded-full">
                                  <FaGitAlt size={27} className="" />
                                </button>
                                </a>
                                <div className="flex flex-col justify-center ml-3">
                                  <p className="text-lg font-bold">Git</p>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  ) : 
                  ( buttonState===1 ? ( // Education
                    <div className="p-4 md:p-0 md:pt-4">
                      <ol className="border-l border-redOfBackgroundPic">
                        <li>
                          <div class="flex items-center">
                            <div class="bg-redOfBackgroundPic w-2 h-2 rounded-full -ml-1 mr-3"></div>
                            <p class="text-gray-500 text-sm">{t("dateAbout")}</p>
                          </div>
                          <p className="text-sm pl-4 font-bold">MSc in Computer Science, Artificial Intelligence</p>
                          <p className="pl-4 text-sm font-semibold">University of Zurich</p>
                          <p className="pl-4 text-xs">{t("mscAbout")}</p>
                        </li>
                        <li>
                          <div class="flex items-center pt-3">
                            <div class="bg-redOfBackgroundPic w-2 h-2 rounded-full -ml-1 mr-3"></div>
                            <p class="text-gray-500 text-sm">01.08.2019 - 31.07.2022</p>
                          </div>
                          <p className="text-sm pl-4 font-bold">BSc in Computer Science, Software Systems</p>
                          <p className="pl-4 text-sm font-semibold">University of Zurich</p>
                          <p className="pl-4 text-xs">{t("bsc1About")}<a href={thesis} download className="underline">Bachelor-Thesis</a>{t("bsc2About")}</p> 
                        </li>
                      </ol>
                    </div>
                  ) : 
                  ( // Work Experience
                    <div className="p-4 md:p-0 md:pt-4">
                      <ol className="border-l border-redOfBackgroundPic">
                        <li>
                          <div class="flex items-center">
                            <div class="bg-redOfBackgroundPic w-2 h-2 rounded-full -ml-1 mr-3"></div>
                            <p class="text-gray-500 text-sm">28.06.2021 - 19.09.2021</p>
                          </div>
                          <p className="text-sm pl-4 font-bold">{t("internshipAbout")}</p>
                          <p className="text-sm pl-4 font-semibold">Abraxas Informatik AG</p>
                          <p className="pl-4 text-xs">{t("abraxasAbout")}</p>
                        </li>
                        <li>
                          <div class="flex items-center pt-3">
                            <div class="bg-redOfBackgroundPic w-2 h-2 rounded-full -ml-1 mr-3"></div>
                            <p class="text-gray-500 text-sm">16.07.2018 - 31.08.2018</p>
                          </div>
                          <p className="text-sm pl-4 font-bold">{t("workshopAbout")}</p>
                          <p className="pl-4 text-sm font-semibold">EMPA St. Gallen</p>
                          <p className="pl-4 text-xs">{t("empaAbout")}</p>
                        </li>
                      </ol>
                    </div>
                  )
                  )}
            </div>
          </div>
        </div>
      </div>
      
    );
  }

  
  export default AboutMe;
  
import {FaGithubSquare, FaLinkedin, FaFacebookSquare} from "react-icons/fa"
import { useTranslation } from "react-i18next"


function Footer() {
    const {t, i18n} = useTranslation()

    return (
        <footer class="bg-redOfBackgroundPic">
            <div class="grid grid-cols-1 gap-8 px-6 py-6 md:grid-cols-3">
                <div className="">
                    <h2 class="mb-2 text-sm font-semibold text-white uppercase flex justify-center">{t("socialFooter")}</h2>
                    <div className="flex justify-center">
                        <a href="https://github.com/sumsumcity" target="_blank" rel="noreferrer"><FaGithubSquare className="w-8 h-8 hover:w-12 hover:h-12 transition-all" /></a>
                        <a className="mx-3" href="https://www.linkedin.com/in/raphael-w%C3%A4spi-104453204/" target="_blank" rel="noreferrer" ><FaLinkedin className="w-8 h-8 hover:w-12 hover:h-12 transition-all" /></a>
                        <a href="https://www.facebook.com/raphael.waspi" target="_blank" rel="noreferrer" ><FaFacebookSquare className="w-8 h-8 hover:w-12 hover:h-12 transition-all"/></a>
                    </div>
                </div>
                <div>
                    <h2 class="flex justify-center mb-2 text-sm font-semibold text-white uppercase ">{t("contactFooter")}</h2>
                    <ul class="flex justify-center text-black">
                        <li class="mb-4">
                            <p className="text-sm lg:text-base">raffi_waespi@hotmail.com</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="flex justify-center mb-2 text-sm font-semibold text-white uppercase ">{t("locationFooter")}</h2>
                    <ul class="flex justify-center text-black ">
                        <li class="mb-4 text-sm lg:text-base">
                            <p>{t("swissFooter")}</p>
                        </li>
                    </ul>
                </div>

            </div>

            <div class="px-4 pb-6 bg-redOfBackgroundPic flex items-center justify-center">
                <div className="border-t-2 md:w-1/3">
                    <span class="text-sm text-white sm:text-center flex justify-center py-3">© 2023. All Rights Reserved by Raphael Wäspi</span>
                </div>
            </div>
        </footer>

    );

}

export default Footer
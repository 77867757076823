import intro_off_sec from "../assets/thm/intro_off_sec.jpeg"
import web_app_sec from "../assets/thm/web_app_sec.jpeg"
import what_is_networking from "../assets/thm/what_is_networking.png"
import intro_to_lan from "../assets/thm/intro_to_lan.png"
import linux_fundamentals from "../assets/thm/linux-fundamentals.png"
import tutorial from "../assets/thm/tutorial.png"
import introductory_networking from "../assets/thm/introductory_networking.png"
import openvpn from "../assets/thm/openvpn.png"
import learning_cybersecurity from "../assets/thm/learning_cybersecurity.jpg"
import web_req from "../assets/htb/web_req.png"
import nmap from "../assets/thm/nmap.png"
import rootMe from "../assets/thm/rootMe.png"
import simple_CTF from "../assets/thm/simple_CTF.png"
import intro_to_academy from "../assets/htb/intro_to_academy.png"
import getting_started from "../assets/htb/getting_started.png"
import cracking_into_htb from "../assets/htb/cracking_itno_htb.png"
import js_deob from "../assets/htb/js_deob.png"
import webApp from "../assets/htb/webApp.png"
import webProxies from "../assets/htb/webProxies.png"
import { useTranslation } from "react-i18next"



function AllSecCourses() {
    const {t, i18n} = useTranslation()

    return (
    <>
    {/** When thm then object-contain */}

        {/** ADD here new CyberSec Course */}

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/110" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={webProxies}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Using Web Proxies</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Apr 10, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("21Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#offensive</span>
                </div>
            </div>
            </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/75" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={webApp}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Web Applications</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Apr 5, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("20Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#general</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/path/13" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={cracking_into_htb}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Cracking into Hack the Box</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Apr 4, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("19Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#path</span>
                </div>
            </div>
        </div>


        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/77" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={getting_started}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Getting Started</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Apr 4, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("18Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#offensive</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/41" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={js_deob}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">JavaScript Deobufuscation</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Feb 6, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("17Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#defensive</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/35" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={web_req}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Web Requests</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Jan 3, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("16Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#general</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-cover h-full w-full" src={intro_to_academy}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Intro to Academy</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Jan 3, 2023</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("15Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://tryhackme.com/room/easyctf" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={simple_CTF}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Simple CTF</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 28, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("14Cybersec")} <a href="https://www.exploit-db.com/">Exploit Database</a> website.
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#CTF</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={rootMe}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">RootMe</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 27, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("13Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#CTF</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={nmap}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Nmap</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 27, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("12Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={learning_cybersecurity}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Learning Cyber Security</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 27, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("11Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={openvpn}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">OpenVPN</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 22, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("10Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={introductory_networking}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Introductory Networking</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 22, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("9Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={tutorial}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Tutorial</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 22, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("8Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={linux_fundamentals}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Linux Fundamentals Part 3</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 20, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("7Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={linux_fundamentals}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Linux Fundamentals Part 2</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 20, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("6Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={linux_fundamentals}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Linux Fundamentals Part 1</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 17, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("5Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={intro_to_lan}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Intro to LAN</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 16, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("4Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={what_is_networking}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">What is Networking?</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 15, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                    {t("3Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={intro_off_sec}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Intro to Offensive Security</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 14, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                    {t("2Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>

        <div class="flex justify-center rounded-2xl">
            <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                <div className="w-full h-40">
                    <a href="https://academy.hackthebox.com/achievement/686151/15" target="_blank" rel="noreferrer">
                        <img class="rounded-t-lg object-contain h-full w-full" src={web_app_sec}/>
                    </a>
                </div>
                <div class="px-6 pt-6">
                    <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Web Application Security</h5>
                    <div className="mt-1 text-xs text-gray-400 mb-2">Dec 14, 2022</div>
                    <p class="mb-4 text-base text-neutral-200">
                        {t("1Cybersec")}
                    </p>
                </div>
                <div class="px-6 pt-4 pb-2">
                    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#tryhackme</span>
                </div>
            </div>
        </div>
    </>
    );
}

export default AllSecCourses;
import Typed from 'typed.js';
import { useEffect, useRef } from 'react';
import background from "../assets/rest/background.jpeg"
import { useTranslation } from "react-i18next"

function Title() {

    const el = useRef(null)
    const {t, i18n} = useTranslation()

 /*   useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [" Student", " Software Developer", " Cybersecurity Hobbyist"],
            startDelay: 150,
            typeSpeed: 150,
            backDelay: 150,
            backSpeed: 150,
            smartBackspace: true,
            showCursor: false,
            loop: true,
        });
    }); */

    return (
        <div className='h-screen w-full bg-gradient-to-tr bg-cover flex' id='home'>
            <img src={background} className='w-full h-full object-cover absolute object-left md:object-left brightness-75'/>
            <div className='flex justify-around items-center w-full px-3 z-10'>
                <div>
                    <h1 className="text-7xl text-white font-arimo">
                        {t("nameTitle")}
                    </h1>
                    <h1 className="text-7xl text-white font-arimo">
                        {t("name2Title")}
                    </h1>
                    {/*
                    <br />
                    <span className='text-white font-arimo tracking-wide text-4xl'>
                        {t("presentenceTitle")}
                    </span>
                    &nbsp;&nbsp;
    <span className='text-white font-arimo tracking-wide text-4xl underline-offset-2' ref={el}></span> */}
                </div>
                <div className="w-1/6" />
            </div>
        </div>      
    );
  }

  
  export default Title;
  
import { useState } from "react"

import bruteForce from "../assets/htb/bruteForce.png"
import tier0 from "../assets/htb/Tier0.png"
import sqlInjection from "../assets/htb/sql_injection.png"
import AllSecCourses from "./AllSecCourses"
import { useTranslation } from "react-i18next"



function Cybersec() {

    const [showAll, setShowAll] = useState(false)
    const {t, i18n} = useTranslation()

    return (
    <div className='w-full py-10 md:py-20' id="activities">
        <div className="flex justify-center ml-3 sm:ml-0">
            <h2 className="font-arimo font-semibold text-3xl">{t("titleCybersec")}</h2>
        </div>
        <div className="mx-auto max-w-screen-lg px-3 py-6">
            <div className="flex justify-end mb-6 text-2xl font-bold">
                {showAll ? (
                        <div className="text-sm flex justify-center"><button onClick={() => setShowAll(!showAll)}>{t("button2Cybersec")} →</button></div>
                    ) : (
                        <div className="text-sm flex justify-center"><button onClick={() => setShowAll(!showAll)}>{t("button1Cybersec")} →</button></div>
                    )}
            </div>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">

            {/** ADD here new CyberSec Course */}
            <div class="flex justify-center rounded-2xl">
                <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                    <div className="w-full h-40">
                        <a href="https://academy.hackthebox.com/achievement/686151/33" target="_blank" rel="noreferrer">
                            <img class="rounded-t-lg object-cover h-full w-full" src={sqlInjection}/>
                        </a>
                    </div>
                    <div class="px-6 pt-6">
                        <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">SQL Injection</h5>
                        <div className="mt-1 text-xs text-gray-400 mb-2">May 11, 2023</div>
                        <p class="mb-4 text-base text-neutral-200">
                            {t("24Cybersec")}
                        </p>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#offensive</span>
                    </div>
                </div>
            </div>

            <div class="flex justify-center rounded-2xl">
                <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                    <div className="w-full h-40">
                        <a href="https://academy.hackthebox.com/achievement/686151/57" target="_blank" rel="noreferrer">
                            <img class="rounded-t-lg object-cover h-full w-full" src={bruteForce}/>
                        </a>
                    </div>
                    <div class="px-6 pt-6">
                        <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Login Brute Forcing</h5>
                        <div className="mt-1 text-xs text-gray-400 mb-2">Apr 16, 2023</div>
                        <p class="mb-4 text-base text-neutral-200">
                            {t("23Cybersec")}
                        </p>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#offensive</span>
                    </div>
                </div>
            </div>

            <div class="flex justify-center rounded-2xl">
                <div class="block max-w-sm rounded-lg shadow-lg bg-neutral-700 border-4 border-neutral-700">
                    <div className="w-full h-40">
                        <a href="https://www.hackthebox.com/achievement/machine/933234/394" target="_blank" rel="noreferrer">
                            <img class="rounded-t-lg object-cover h-full w-full" src={tier0}/>
                        </a>
                    </div>
                    <div class="px-6 pt-6">
                        <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-50">Starting Machines Tier 0</h5>
                        <div className="mt-1 text-xs text-gray-400 mb-2">Apr 11, 2023</div>
                        <p class="mb-4 text-base text-neutral-200">
                            {t("22Cybersec")}
                        </p>
                    </div>
                    <div class="px-6 pt-4 pb-2">
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#hackthebox</span>
                        <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#machine</span>
                    </div>
                </div>
            </div>


                {showAll ? (
                    <>
                        <AllSecCourses />
                    </>
                ) : (
                    <></>
                )}
            </div>
            {showAll ? (
                    <div className="text-sm flex justify-center mt-10"><button className="bg-neutral-700 hover:bg-transparent hover:text-neutral-700  text-white font-bold hover:border-neutral-700 py-2 px-4 border-2 border-neutral-700 rounded transition-all duration-200" onClick={() => setShowAll(!showAll)}>{t("button4Cybersec")}</button></div>
                ) : (
                    <div className="text-sm flex justify-center mt-10"><button className="bg-neutral-700 hover:bg-transparent hover:text-neutral-700  text-white font-bold hover:border-neutral-700 py-2 px-4 border-2 border-neutral-700 rounded transition-all duration-200" onClick={() => setShowAll(!showAll)}>{t("button3Cybersec")}</button></div>
                )}
        </div>

        
    </div>
      
    );
  }

  
  export default Cybersec;
  
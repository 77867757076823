import { useState, useEffect } from "react";
import { CircleFlag } from 'react-circle-flags'
import {Link} from "react-scroll"
import { useTranslation } from "react-i18next"
import { IoClose } from 'react-icons/io5';
import { AiOutlineLeft } from 'react-icons/ai';
import {FaGithubSquare, FaLinkedin, FaFacebookSquare} from "react-icons/fa"

function Navbar() {

    const {t, i18n} = useTranslation()

    const [language, setLanguage] = useState("en")
    const [isLangOpen, setIsLangOpen] = useState(false)
    const [transNavbar, setTransNavbar] = useState(true)
    const [navUnderlined, setNavUnderlined] = useState("home")
    const [hamburgerClicked, setHamburgerClicked] = useState(false)

    // Prevent Scrolling
    useEffect(() => {
        const body = document.querySelector('body');

        if (hamburgerClicked) {
        // Disable scrolling when something is open
        body.style.overflow = 'hidden';
        } else {
        // Enable scrolling when nothing is open
        body.style.overflow = 'auto';
        }

        // Clean up by enabling scrolling when the component unmounts
        return () => {
        body.style.overflow = 'auto';
        }
    }, [hamburgerClicked]);

    const changeBackgroundAndUnderline = () => {
        if(window.scrollY >= 300) {
            setTransNavbar(false)
        } 
        else {
            setTransNavbar(true)
        }

        if(document.getElementById('about').getBoundingClientRect().y > 50) {
            setNavUnderlined("home")
        }
        else if (document.getElementById('about').getBoundingClientRect().y <= 50 && document.getElementById('projects').getBoundingClientRect().y > 50){
            setNavUnderlined("about")
        }
        else if (document.getElementById('projects').getBoundingClientRect().y <= 50 && document.getElementById('activities').getBoundingClientRect().y > 50){
            setNavUnderlined("projects")
        }
        else{
            setNavUnderlined("activities")
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang)
        i18n.changeLanguage(lang)
        setIsLangOpen(false)
    }

    window.addEventListener("scroll", changeBackgroundAndUnderline)

    return (
    <nav class={transNavbar ? ("bg-transparent px-8 py-2.5 absolute w-full z-20 top-0 flex justify-center") : "bg-redOfBackgroundPic px-4 py-2.5 fixed w-full z-20 top-0 left-0 animate-bounce-once flex justify-center"}>
        <div class="container flex flex-wrap items-center justify-between mix-blend-overlay">
            <a href="/" class="flex items-center">
                <span class="self-center text-xl font-semibold text-white uppercase tracking-wider">Raphael</span>
            </a>
            <div class="flex md:order-2">
                {/** Language */}
                <button onClick={() => setIsLangOpen(!isLangOpen)} className="inline-flex items-center justify-center px-4 py-2 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-600 translation-all duration-200">
                    <div className="flex w-full">
                        {language==="en" ? (<><CircleFlag countryCode="gb" className="w-6 mr-2" /><p className="text-white">English</p></>) : (<><CircleFlag countryCode="de" className="w-6 mr-2" /><p className="text-white">Deutsch</p></>)}
                    </div>
                </button>
                {isLangOpen && (
                    <div className="bg-gray-100 absolute top-16 rounded-lg px-4 py-2">
                        <button onClick={() => changeLanguage("en")} class="flex py-1">
                            <div className="flex w-full hover:underline">
                            <CircleFlag countryCode="gb" className="w-6 mr-2" />
                                English
                            </div>
                        </button>
                        <button onClick={() => changeLanguage("de")} class="flex py-1">
                            <div className="flex w-full hover:underline">
                            <CircleFlag countryCode="de" className="w-6 mr-2" />
                                Deutsch
                            </div>
                        </button>
                    </div>
                )}
                
                {/** Navbar Hamburger */}
                <button onClick={() => setHamburgerClicked(!hamburgerClicked)} class="inline-flex items-center p-2 text-sm text-gray-100 rounded-lg md:hidden hover:bg-gray-600 translation-all duration-200">
                    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                </button>

            </div>
            <div class="items-center justify-between w-full md:flex md:w-auto md:order-1">
                <ul class="hidden md:flex p-4 bg-transparent rounded-lg space-x-8 mt-0 text-sm font-medium">
                <li>
                    <Link to="home" spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="home" ? "text-white cursor-pointer underline" : "text-white cursor-pointer"}>Home</p></Link>
                </li>
                <li>
                    <Link to="about" spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="about" ? "text-white cursor-pointer underline" : "text-white cursor-pointer"}>{t("aboutNav")}</p></Link>
                </li>
                <li>
                    <Link to="projects" spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="projects" ? "text-white cursor-pointer underline" : "text-white cursor-pointer"}>{t("projectsNav")}</p></Link>
                </li>
                <li>
                    <Link to="activities" spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="activities" ? "text-white cursor-pointer underline" : "text-white cursor-pointer"}>{t("activitiesNav")}</p></Link>
                </li>
                </ul>
            </div>

            {hamburgerClicked ? 
                (<div className="fixed h-screen right-0 top-0 w-2/5 bg-gray-600 ">
                    <div className="h-16">
                        <button className="absolute top-0 left-0 m-4 text-white hover:text-gray-300 focus:outline-none" onClick={() => setHamburgerClicked(false)}>
                            <AiOutlineLeft size={24} />
                        </button>
                        <button className="absolute top-0 right-0 m-4 text-white hover:text-gray-300 focus:outline-none" onClick={() => setHamburgerClicked(false)}>
                            <IoClose size={24} />
                        </button>
                    </div>
                    <ul className="flex flex-col p-8 mt-16 text-xl text-white font-medium space-y-4">
                    <li>
                        <Link to="home" onClick={() => setHamburgerClicked(false)} spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="home" ? "cursor-pointer underline" : "cursor-pointer"}>Home</p></Link>
                    </li>
                    <li>
                        <Link to="about" onClick={() => setHamburgerClicked(false)} spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="about" ? "cursor-pointer underline" : "cursor-pointer"}>{t("aboutNav")}</p></Link>
                    </li>
                    <li>
                        <Link to="projects" onClick={() => setHamburgerClicked(false)} spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="projects" ? "cursor-pointer underline" : "cursor-pointer"}>{t("projectsNav")}</p></Link>
                    </li>
                    <li>
                        <Link to="activities" onClick={() => setHamburgerClicked(false)} spy={true} smooth={true} offset={0} duration={500}><p className={navUnderlined==="activities" ? "cursor-pointer underline" : "cursor-pointer"}>{t("activitiesNav")}</p></Link>
                    </li>
                    </ul>
                    <div className="flex justify-center">
                        <a href="https://github.com/sumsumcity" target="_blank" rel="noreferrer"><FaGithubSquare className="w-8 h-8 text-gray-200" /></a>
                        <a className="mx-3" href="https://www.linkedin.com/in/raphael-w%C3%A4spi-104453204/" target="_blank" rel="noreferrer" ><FaLinkedin className="w-8 h-8 text-gray-200" /></a>
                        <a href="https://www.facebook.com/raphael.waspi" target="_blank" rel="noreferrer" ><FaFacebookSquare className="w-8 h-8 text-gray-200"/></a>
                    </div>
                </div>
                ) : 
                (<></>)
            }

            
        </div>
    </nav>

    

    );
};

export default Navbar